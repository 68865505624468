<template>
  <div>
    <Breadcrumbs page_title="settingsection" :items="breadcrumbs" />

    <v-container fluid v-if="isoaccess">
      <v-card class="rounded-lg">
        <v-card>
          <v-row class="ma-1">
            <v-col cols="12">
              <!-- Main Topic List -->
              <v-form ref="form" v-model="valid" lazy-validation>
                <div v-for="(item, i) in mainTopic_Items" :key="i">
                  <v-row class="ma-1">
                    <v-col cols="12" xs="12" sm="12" md="6">
                      <label for="maintopic" class="font-style">
                        {{ $t("maintopic") }}
                        <span style="color: red">*</span>
                      </label>
                      <v-text-field
                        :rules="TRtopicRules"
                        style="margin-top: 7px"
                        outlined
                        dense
                        :counter="55"
                        v-model="item.mainTopic"
                        hide-details="auto"
                        @input="
                          ($event) => {
                            if ($event.length <= 55) item.mainTopic = $event;
                            else item.mainTopic = $event.slice(0, 54);
                          }
                        "
                      ></v-text-field>
                    </v-col>
                    <v-col
                      cols="12"
                      xs="12"
                      sm="12"
                      md="6"
                      style="text-align: right !important"
                    >
                      <v-btn
                        @click="scrollToBottomMain(item)"
                        width="22"
                        height="30"
                        class="smallBtnMain"
                      >
                        <v-icon center small>mdi-arrow-down</v-icon>
                      </v-btn>

                      <v-btn
                        @click="scrollToTopMain(item)"
                        class="ml-3 smallBtnMain"
                        width="22"
                        height="30"
                      >
                        <v-icon center small>mdi-arrow-up</v-icon>
                      </v-btn>
                      <v-btn
                        fab
                        x-small
                        right
                        class="custom_main_closeBtn"
                        @click="Delete_MainTopic(item)"
                      >
                        <v-icon>mdi-close</v-icon>
                      </v-btn>
                    </v-col>
                  </v-row>
                  <!-- Sub Topic & Course List -->
                  <v-row class="ma-1">
                    <v-col cols="12">
                      <v-card
                        v-for="(item, i) in WhereSubTopic(item.mainTopicId)"
                        :key="i"
                      >
                        <!-- Sub Topic -->
                        <v-row class="ma-1">
                          <v-col cols="12" md="6">
                            <label for="subtopic" class="font-style">{{
                              $t("subtopic")
                            }}</label>
                            <v-text-field
                              style="margin-top: 7px"
                              outlined
                              dense
                              :counter="55"
                              v-model="item.subTopic"
                              @input="
                                ($event) => {
                                  if ($event.length <= 55)
                                    item.subTopic = $event;
                                  else item.subTopic = $event.slice(0, 54);
                                }
                              "
                              hide-details="auto"
                            ></v-text-field>
                          </v-col>
                          <!-- Scroll page up or down action arrow!......... -->
                          <v-col cols="12" md="6" style="text-align: right">
                            <v-btn
                              @click="scrollToBottomSub(item)"
                              width="22"
                              height="30"
                              class="smallBtnSub"
                            >
                              <v-icon center small>mdi-arrow-down</v-icon>
                            </v-btn>

                            <v-btn
                              @click="scrollToTopSub(item)"
                              class="ml-3 smallBtnSub"
                              width="22"
                              height="30"
                            >
                              <v-icon center small>mdi-arrow-up</v-icon>
                            </v-btn>
                          </v-col>
                        </v-row>

                        <!-- Course ddl List -->
                        <v-row
                          class="ma-1"
                          v-for="(courseTopic, k) in WhereCourse(
                            item.subTopicId
                          )"
                          :key="k"
                        >
                          <v-col cols="12" md="6" class="pt-1 pb-1">
                            <label for="course" class="font-style">
                              {{ $t("course") }}
                              <span style="color: red">*</span>
                            </label>
                            <v-select
                              :items="Courseitems"
                              :rules="TRCourseRules"
                              :error-messages="validationErrors"
                              v-model="courseTopic.idOfCourse"
                              return-object
                              item-text="courseName"
                              item-value="id"
                              chips
                              solo
                              dense
                              @click="showChangeList(courseTopic)"
                              @keydown="showChangeList(courseTopic)"
                              @input="dropdownClose"
                              clearable
                              :label="
                                !courseTopic.showCourseText
                                  ? '-- Please select --'
                                  : courseTopic.course
                              "
                              outlined
                              color="#C0C0C0"
                              class="custom-label-color"
                              :menu-props="{ closeOnContentClick: searchfocus }"
                            >
                              <template v-slot:error="{ errorMessage }">
                                <div class="error-message">
                                  {{ errorMessage }}
                                </div>
                              </template>
                              <template #append>
                                <v-icon>mdi-chevron-down</v-icon>
                              </template>
                              <template v-slot:prepend-item>
                                <v-list-item>
                                  <v-list-item-content>
                                    <v-text-field
                                      v-model="searchcourse"
                                      :placeholder="$t('search')"
                                      @focus="
                                        showChangeList(courseTopic),
                                          (searchfocus = false)
                                      "
                                      @input="
                                        searchInLists(), (searchfocus = true)
                                      "
                                      autocomplete="off"
                                      hide-details
                                      class="searchinlist"
                                      append-icon="mdi-magnify"
                                    ></v-text-field>
                                  </v-list-item-content>
                                </v-list-item>
                                <v-list-item ripple>
                                  <v-list-item-content
                                    style="text-align: right"
                                  >
                                    <v-list-item-title
                                      style="
                                        color: #ff6060;
                                        font-size: 15px;
                                        padding-right: 10px;
                                      "
                                    >
                                      {{ "Choose only 1 course" }}
                                      <span style="color: red">*</span>
                                    </v-list-item-title>
                                  </v-list-item-content>
                                </v-list-item>
                              </template>

                              <template v-slot:item="{ item }">
                                <v-list-item
                                  @click="SelectCourse(item, courseTopic)"
                                  class="custom-border"
                                >
                                  <v-list-item-content>
                                    <v-list-item-title>
                                      <div class="course-container">
                                        {{ item.courseName }}
                                      </div></v-list-item-title
                                    >
                                  </v-list-item-content>
                                </v-list-item>
                              </template>

                              <template v-slot:selection="{ item }">
                                <div class="course-container">
                                  {{ item.courseName }}
                                </div>
                              </template>
                            </v-select>
                          </v-col>
                          <!-- Scroll course!......... -->
                          <v-col cols="12" md="6" class="pt-1 pb-1">
                            <v-btn
                              @click="scrollToBottomCourse(courseTopic)"
                              width="22"
                              height="30"
                              class="smallBtnCourse"
                            >
                              <v-icon center small>mdi-arrow-down</v-icon>
                            </v-btn>

                            <v-btn
                              @click="scrollToTopCourse(courseTopic)"
                              class="ml-3 smallBtnCourse"
                              width="22"
                              height="30"
                            >
                              <v-icon center small>mdi-arrow-up</v-icon>
                            </v-btn>

                            <v-btn
                              @click="Delete_Course(courseTopic, item)"
                              class="ml-3 smallBtnDelete"
                              width="22"
                              height="30"
                              text
                            >
                              <v-icon center>mdi-delete-outline</v-icon>
                            </v-btn>
                          </v-col>
                        </v-row>
                        <v-row class="ma-1">
                          <v-col cols="12">
                            <v-card
                              style="box-shadow: unset; padding-right: 34px"
                            >
                              <div
                                style="
                                  padding: 8px;
                                  border: 1px solid #c0c0c0;
                                  border-radius: 8px;
                                "
                              >
                                <v-btn
                                  icon
                                  class="ml-1"
                                  size="35"
                                  @click="Add_Course(item), dropdownClose()"
                                  style="
                                    cursor: pointer;
                                    background-color: #4fb14e;
                                    color: #ffffff;
                                    border-radius: 8px !important;
                                    width: 25px;
                                    height: 25px;
                                  "
                                >
                                  <v-icon color="white">mdi-plus</v-icon>
                                </v-btn>
                                <span
                                  style="
                                    color: #4fb14e;
                                    font-size: 18px;
                                    cursor: pointer;
                                  "
                                  class="ml-2"
                                  @click="Add_Course(item), dropdownClose()"
                                  >{{ $t("addcourse") }}</span
                                >
                              </div>
                            </v-card>
                          </v-col>
                        </v-row>
                        <v-row class="ma-1">
                          <v-col cols="12" md="6" lg="6"></v-col>
                          <v-col
                            cols="12"
                            md="6"
                            lg="6"
                            style="text-align: right; padding-right: 30px"
                          >
                            <v-btn
                              class="text-capitalize"
                              @click="Delete_SubTopic(item)"
                              style="
                                background-color: transparent;
                                box-shadow: unset;
                                cursor: pointer;
                                color: #ff6060;
                                font-size: 14px;
                              "
                            >
                              <v-icon class="mr-2" style="font-size: 20px"
                                >mdi-delete-outline</v-icon
                              >
                              {{ $t("delete") }}
                            </v-btn>
                          </v-col>
                        </v-row>
                      </v-card>
                    </v-col>
                  </v-row>
                </div>
              </v-form>
            </v-col>
          </v-row>
          <v-row class="ma-1">
            <v-col cols="12" md="10" style="text-align: right">
              <v-btn
                text
                class="mr-5 text-capitalize TRtopicbtn"
                @click="AddMainTopicFromBtn"
              >
                <v-icon color="#A6CC39">mdi-plus</v-icon>
                {{ $t("addmaintopic") }}
              </v-btn>
              <v-btn
                text
                class="ml-3 text-capitalize TRtopicbtn"
                @click="AddSubTopic"
              >
                <v-icon color="#A6CC39">mdi-plus</v-icon>
                {{ $t("addsubtopic") }};
              </v-btn>
            </v-col>
          </v-row>
          <v-row class="ma-1">
            <v-col style="text-align: right">
              <v-btn
                width="135"
                class="text-capitalize white--text TRtopicsavebtn"
                @click="SaveBtn"
                color="#A6CC39"
                >{{ $t("save") }}</v-btn
              >
            </v-col>
          </v-row>
        </v-card>
      </v-card>
    </v-container>
    <v-dialog v-model="crudloading" v-if="isoaccess" persistent width="300">
      <v-card color="#4FB14E" dark>
        <v-card-text class="white--text">
          Loading Please Wait...
          <v-progress-linear
            indeterminate
            color="#ffffff"
            class="mb-0"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
    <PermissionDialog
      :show="permissionDialog"
      title="Error"
      :text="permissionMessage"
      @close="permissionDialog = false"
    />
  </div>
</template>

<script>
import axios from "axios";
import { uuid } from "vue-uuid";

export default {
  components: {},

  data: () => ({
    permissionDialog: false,
    permissionMessage: "",
    createdById: null,
    searchfocus: true,
    isoaccess: false,
    courseCopy: [],
    //extractedCourseIdsCopy: [],
    originalCourse: [],
    extractedCourseIds: [],
    crudloading: true,
    validationErrors: [],
    valid: false,
    uuid: uuid.v1(),
    quizId: uuid.v4(),
    CouresSelect: null,
    searchcourse: null,
    selectedCourse: {},
    Courseitems: [],
    breadcrumbs: [
      {
        sidebar_tilte: "trainingroadmap",
      },
      {
        text: "settingsection",
      },
    ],
    mainTopic_Items: [],
    subTopic_Items: [],
    course_Items: [],
    Controller: null,
    assignmentId: 0,
  }),
  computed: {
    TRtopicRules() {
      return [
        (v) => !!v || "Topic is required",
        (v) =>
          v ? v.length <= 55 || "Text must not exceed 55 characters" : "",
      ];
    },
    TRCourseRules() {
      return [(v) => !!v || "Course is required"];
    },
  },
  mounted() {
    const accessqr = localStorage.getItem("isoaccess");
    this.isoaccess = JSON.parse(accessqr);
    const userdata = JSON.parse(
      localStorage.getItem("vivek_authenticated_user_data")
    );
    this.createdById = userdata.memberId;

    this.GetCourseItems();
  },
  methods: {
    dropdownClose() {
      //works when dropdown is closed...
      let that = this;
      that.Courseitems = that.originalCourse;

      //show selected course text
      let tempC = that.course_Items.map((m) => ({
        ...m,
        showCourseText: false, //lethowCourseText
      }));
      that.course_Items = tempC;
      that.searchcourse = null;
    },
    showChangeList(check) {
      let that = this;

      let restOfCourse = [];

      if (check.idOfCourse == null) {
        // adding new course

        restOfCourse = that.Courseitems.filter(
          (course) => !that.extractedCourseIds.includes(course.id)
        );
      } else {
        let push_id = null;
        if (Number.isInteger(check.idOfCourse)) {
          push_id = check.idOfCourse;
        } else {
          push_id = check.idOfCourse.id;
        }
        that.extractedCourseIds = that.extractedCourseIds.filter(
          (f) => f != push_id
        );
        restOfCourse = that.Courseitems.filter(
          (course) => !that.extractedCourseIds.includes(course.id)
        );
      }
      that.Courseitems = restOfCourse;

      let tempC = that.course_Items.map((m) => ({
        ...m,
        showCourseText: true,
      }));
      that.course_Items = tempC;
    },
    Delete_SubTopic(item) {
      let that = this;
      let checkPermission = that.check_Permissions("trainingroadmapiso");
      if (!checkPermission) {
        that.permissionDialog = true;
        that.permissionMessage = "You don't have permission to delete this.";
      } else {
        let check_sub = [];
        check_sub = that.subTopic_Items.filter(
          (s) => s.mainTopicId == item.mainTopicId
        );
        if (check_sub.length <= 1) {
          alert("Main Topic should have at least one Course.");
          return;
        }
        //.............
        //delete sub topic
        const index = that.subTopic_Items.findIndex(
          (subTopic_Items) => subTopic_Items.subTopicId == item.subTopicId
        );

        for (let i = that.course_Items.length; i--; ) {
          if (that.course_Items[i].subTopicId === item.subTopicId)
            that.course_Items.splice(i, 1);
        }
        that.subTopic_Items.splice(index, 1);
        that.updateDropdownCourseList();
      }
    },
    Delete_MainTopic(item) {
      let that = this;
      let checkPermission = that.check_Permissions("trainingroadmapiso");
      if (!checkPermission) {
        that.permissionDialog = true;
        that.permissionMessage = "You don't have permission to delete this.";
      } else {
        const index = that.mainTopic_Items.findIndex(
          (mainTopic_Items) => mainTopic_Items.mainTopicId == item.mainTopicId
        );

        for (let i = that.course_Items.length; i--; ) {
          if (that.course_Items[i].mainTopicId === item.mainTopicId) {
            that.course_Items.splice(i, 1);
          }
        }
        for (let j = that.subTopic_Items.length; j--; ) {
          if (that.subTopic_Items[j].mainTopicId === item.mainTopicId) {
            that.subTopic_Items.splice(j, 1);
          }
        }
        that.mainTopic_Items.splice(index, 1);
        that.updateDropdownCourseList();
      }
    },
    updateDropdownCourseList() {
      let that = this;
      that.extractedCourseIds = that.course_Items.map(
        (item) => item.idOfCourse
      ); // refresh dropdown course list after deleting contents..
    },
    AddMainTopicFromBtn() {
      let checkPermission = this.check_Permissions("trainingroadmapiso");
      if (!checkPermission) {
        this.permissionDialog = true;
        this.permissionMessage =
          "You don't have permission to access this function.";
      } else {
        this.AddMainTopic();
        this.AddSubTopic();
      }
    },
    AddMainTopic() {
      let main_Item = {
        mainTopicId: uuid.v4(),
        companyId: localStorage.getItem("companyID"),
        mainTopicNo: 1,
        mainTopic: null,
        createBy: null,
        createDate: null,
        updateBy: null,
        updateDate: null,
        activeFlag: true,
      };
      this.mainTopic_Items.push(main_Item);
    },
    AddSubTopic() {
      let checkPermission = this.check_Permissions("trainingroadmapiso");
      if (!checkPermission) {
        this.permissionDialog = true;
        this.permissionMessage =
          "You don't have permission to access this function.";
      } else {
        let main_Item = this.mainTopic_Items[this.mainTopic_Items.length - 1];

        let subTopicTemp = {
          subTopicId: uuid.v4(),
          companyId: main_Item.companyId,
          mainTopicId: main_Item.mainTopicId,
          subTopicNo: 1,
          subTopic: "",
          createBy: null,
          createDate: null,
          updateBy: null,
          updateDate: null,
          activeFlag: true,
        };
        this.subTopic_Items.push(subTopicTemp);

        //add courses in sub topics
        let courseTemp = {
          courseId: uuid.v4(),
          companyId: subTopicTemp.companyId,
          mainTopicId: subTopicTemp.mainTopicId,
          subTopicId: subTopicTemp.subTopicId,
          courseNo: 1,
          course: null,
          rule: false,
        };
        this.course_Items.push(courseTemp);
      }
    },
    searchInLists() {
      let that = this;

      //filtered selected course list
      let restOfCourse = [];
      restOfCourse = that.courseCopy.filter(
        (course) => !that.extractedCourseIds.includes(course.id)
      );
      //.....................................

      if (!that.searchcourse) {
        that.Courseitems = that.courseCopy;
      }

      that.Courseitems =
        that.searchcourse != null
          ? restOfCourse.filter((x) => {
              return (
                x.courseName
                  .toLowerCase()
                  .indexOf(that.searchcourse.toLowerCase()) > -1
              );
            })
          : that.courseCopy;
    },

    SelectCourse(item, courseTopic) {
      this.CouresSelect = item.courseID;
      this.selectedCourse = item;

      courseTopic.course = item.courseName;
      courseTopic.courseNo = item.id; //
      courseTopic.idOfCourse = item.id;

      this.extractedCourseIds = [];

      for (let c of this.course_Items) {
        if (Number.isInteger(c.idOfCourse)) {
          this.extractedCourseIds.push(c.idOfCourse);
        } else {
          if (c) {
            if (c.idOfCourse.id != null) {
              this.extractedCourseIds.push(c.idOfCourse.id);
            }
          }
        }
      }

      if (this.searchfocus) {
        this.dropdownClose();
      }
    },
    async GetCourseItems() {
      let that = this;
      let request = {
        companyId: localStorage.getItem("companyID"),
      };
      axios
        .post(`${this.web_url}ISO/GetCoursefromCompany`, request)
        .then(function (response) {
          if (response.data.status == 0) {
            let temp = response.data.data.assignment.map((v) => ({
              ...v,
            }));
            that.Courseitems = temp.sort((a, b) =>
              a.courseName.toLowerCase() > b.courseName.toLowerCase() ? 1 : -1
            );
            that.courseCopy = that.Courseitems;
            that.originalCourse = that.Courseitems;
            that.GetISOAssignmentDetail();
          }
        })
        .catch(function (err) {
          throw err;
        });
    },
    Delete_Course(item, sub_item) {
      let checkPermission = this.check_Permissions("trainingroadmapiso");
      if (!checkPermission) {
        this.permissionDialog = true;
        this.permissionMessage = "You don't have permission to delete this.";
      } else {
        const index = this.course_Items.findIndex(
          (g) => g.courseId === item.courseId
        );
        if (index !== -1) {
          this.course_Items.splice(index, 1);
        }
        this.updateDropdownCourseList();

        //for in case user delete all course and forget to add one under related topic..
        //course is require value....
        let check_sub = [];
        check_sub = this.course_Items.filter(
          (s) => s.subTopicId == sub_item.subTopicId
        );

        if (check_sub.length == 0) {
          this.Add_Course(sub_item);
        }
      }
    },
    Add_Course(item) {
      //add courses in sub topics
      let courseTemp = {
        courseId: uuid.v4(),
        companyId: item.companyId,
        mainTopicId: item.mainTopicId,
        subTopicId: item.subTopicId,
        courseNo: null,
        course: null,
        idOfCourse: null,
        rule: false,
        showCourseText: false,
      };
      this.course_Items.push(courseTemp);
    },
    WhereSubTopic(mainTopicId) {
      return this.subTopic_Items.filter((x) => x.mainTopicId == mainTopicId);
    },
    WhereCourse(subTopicId) {
      return this.course_Items.filter((x) => x.subTopicId == subTopicId);
    },
    scrollToBottomMain(item) {
      let indexAll = [];
      for (let element of this.mainTopic_Items) {
        const indexQuestionAll = this.mainTopic_Items.findIndex(
          (mainTopic_Items) =>
            mainTopic_Items.mainTopicId == element.mainTopicId
        );
        indexAll.push(indexQuestionAll);
      }

      if (indexAll.length > 0) {
        const indexMaintopic = this.mainTopic_Items.findIndex(
          (mainTopic_Items) => mainTopic_Items.mainTopicId == item.mainTopicId
        );

        const indexPosition = indexAll.findIndex((x) => x == indexMaintopic);

        if (indexAll[indexPosition + 1] != undefined) {
          this.mainTopic_Items.splice(indexMaintopic, 1);
          this.mainTopic_Items.splice(indexAll[indexPosition + 1], 0, item);
        }
      }
    },
    scrollToTopMain(item) {
      let indexAll = [];
      for (let element of this.mainTopic_Items) {
        const indexQuestionAll = this.subTopic_Items.findIndex(
          (mainTopic_Items) =>
            mainTopic_Items.mainTopicId == element.mainTopicId
        );
        indexAll.push(indexQuestionAll);
      }
      if (indexAll.length > 0) {
        const indexMaintopic = this.mainTopic_Items.findIndex(
          (mainTopic_Items) => mainTopic_Items.mainTopicId == item.mainTopicId
        );

        const indexPosition = indexAll.findIndex((x) => x == indexMaintopic);

        if (indexAll[indexPosition - 1] != undefined) {
          this.mainTopic_Items.splice(indexMaintopic, 1);
          this.mainTopic_Items.splice(indexAll[indexPosition - 1], 0, item);
        }
      }
    },
    scrollToBottomSub(item) {
      let indexAll = [];
      for (let element of this.subTopic_Items) {
        if (element.mainTopicId == item.mainTopicId) {
          const indexQuestionAll = this.subTopic_Items.findIndex(
            (subTopic_Items) => subTopic_Items.subTopicId == element.subTopicId
          );
          indexAll.push(indexQuestionAll);
        }
      }

      if (indexAll.length > 0) {
        const indexSubtopic = this.subTopic_Items.findIndex(
          (subTopic_Items) => subTopic_Items.subTopicId == item.subTopicId
        );

        const indexPosition = indexAll.findIndex((x) => x == indexSubtopic);

        if (indexAll[indexPosition + 1] != undefined) {
          this.subTopic_Items.splice(indexSubtopic, 1);
          this.subTopic_Items.splice(indexAll[indexPosition + 1], 0, item);
        }
      }
    },
    scrollToTopSub(item) {
      let indexAll = [];
      for (let element of this.subTopic_Items) {
        if (element.mainTopicId == item.mainTopicId) {
          const indexQuestionAll = this.subTopic_Items.findIndex(
            (subTopic_Items) => subTopic_Items.subTopicId == element.subTopicId
          );
          indexAll.push(indexQuestionAll);
        }
      }
      if (indexAll.length > 0) {
        const indexSubtopic = this.subTopic_Items.findIndex(
          (subTopic_Items) => subTopic_Items.subTopicId == item.subTopicId
        );

        const indexPosition = indexAll.findIndex((x) => x == indexSubtopic);

        if (indexAll[indexPosition - 1] != undefined) {
          this.subTopic_Items.splice(indexSubtopic, 1);
          this.subTopic_Items.splice(indexAll[indexPosition - 1], 0, item);
        }
      }
    },
    scrollToBottomCourse(item) {
      let indexAll = [];
      for (let element of this.course_Items) {
        if (element.subTopicId == item.subTopicId) {
          const indexQuestionAll = this.course_Items.findIndex(
            (course_Items) => course_Items.courseId == element.courseId
          );
          indexAll.push(indexQuestionAll);
        }
      }

      if (indexAll.length > 0) {
        const indexCourse = this.course_Items.findIndex(
          (course_Items) => course_Items.courseId == item.courseId
        );

        const indexPosition = indexAll.findIndex((x) => x == indexCourse);

        if (indexAll[indexPosition + 1] != undefined) {
          this.course_Items.splice(indexCourse, 1);
          this.course_Items.splice(indexAll[indexPosition + 1], 0, item);
        }
      }
    },
    scrollToTopCourse(item) {
      let indexAll = [];
      for (let element of this.course_Items) {
        if (element.subTopicId == item.subTopicId) {
          const indexQuestionAll = this.course_Items.findIndex(
            (course_Items) => course_Items.courseId == element.courseId
          );
          indexAll.push(indexQuestionAll);
        }
      }
      if (indexAll.length > 0) {
        const indexCourse = this.course_Items.findIndex(
          (course_) => course_.courseId == item.courseId
        );
        const indexPosition = indexAll.findIndex((x) => x == indexCourse);
        if (indexAll[indexPosition - 1] != undefined) {
          this.course_Items.splice(indexCourse, 1);
          this.course_Items.splice(indexAll[indexPosition - 1], 0, item);
        }
      }
    },

    addCourses(courseFilter, subTopic) {
      let temp = subTopic;
      for (let elementCourse of courseFilter) {
        temp.courseList.push({
          courseId:
            elementCourse.courseId.length > 8 ? 0 : elementCourse.courseId,
          companyId: localStorage.getItem("companyID"),
          subTopicId: subTopic.subTopicId.length > 8 ? 0 : subTopic.subTopicId,
          course: elementCourse?.course?.courseName || elementCourse.course,
          idOfCourse: elementCourse?.idOfCourse?.id || elementCourse.idOfCourse,
          flagCourseSelect: true,
          createOrUpdateBy: this.createdById,
        });
      }
      return temp;
    },
    prepareDataToSave() {
      let that = this;
      let temp = [];
      for (let mainTopicTemp of that.mainTopic_Items) {
        let subTopicTemp = that.subTopic_Items.filter(
          (x) => x.mainTopicId == mainTopicTemp.mainTopicId
        );

        let courseTemp = that.course_Items.filter(
          (x) => x.mainTopicId == mainTopicTemp.mainTopicId
        );

        if (mainTopicTemp.mainTopic == null || mainTopicTemp.mainTopic == "") {
          alert("Please add Topic");
          return;
        }

        let mainTopic = {
          mainTopicId:
            mainTopicTemp.mainTopicId.length > 8
              ? 0
              : mainTopicTemp.mainTopicId,
          companyId: localStorage.getItem("companyID"),
          assignmentId: mainTopicTemp.assignmentId,
          mainTopic: mainTopicTemp.mainTopic,
          subTopicList: [],
        };

        for (let elementsubTopic of subTopicTemp) {
          let subTopic = {
            subTopicId:
              elementsubTopic.subTopicId.length > 8
                ? 0
                : elementsubTopic.subTopicId,
            companyId: localStorage.getItem("companyID"),
            assignmentId: mainTopic.assignmentId,
            mainTopicId: mainTopic.mainTopicId,
            subTopic: elementsubTopic.subTopic,
            createOrUpdateBy: that.createdById,
            courseList: [],
          };

          let courseFilter = courseTemp.filter(
            (x) => x.subTopicId == elementsubTopic.subTopicId
          );

          let temp_subTopic = that.addCourses(courseFilter, subTopic);

          mainTopic.subTopicList.push(temp_subTopic);
        }
        temp.push(mainTopic);
      }
      return temp;
    },
    async SaveBtn() {
      let that = this;
      let checkPermission = that.check_Permissions("trainingroadmapiso");
      if (!checkPermission) {
        that.permissionDialog = true;
        that.permissionMessage = "You don't have permission to save.";
      } else {
        if (that.$refs.form.validate()) {
          that.crudloading = true;

          let request = {
            assignmentId: that.assignmentId,
            companyId: localStorage.getItem("companyID"),
            createOrUpdateBy: that.createdById,
            mainTopicList: [],
          };
          request.mainTopicList = that.prepareDataToSave();

          axios
            .post(`${this.web_url}ISO/AddOrEditISOAssignment`, request)
            .then(function (response) {
              if (response.data.status == 0) {
                alert("save successful");
                that.crudloading = false;
                that.$router.push("/trainingroadmap/settingposition");
              }
            })
            .catch(function (err) {
              throw err;
            });
        }
      }
    },
    async GetISOAssignmentDetail() {
      let that = this;
      that.crudloading = true;
      let request = {
        companyId: localStorage.getItem("companyID"),
        assignmentId: 0,
      };
      axios
        .post(`${this.web_url}ISO/GetISOAssignmentDetail_V2`, request)
        .then(function (response) {
          if (response.data.status == 0) {
            if (response.data.data?.companyId != null) {
              that.mainTopic_Items = response.data.data.mainTopicList;
              that.subTopic_Items = response.data.data.subTopicList;
              that.course_Items = response.data.data.courseList.map((n) => ({
                ...n,
                showCourseText: false,
              }));

              that.assignmentId =
                response.data.data.mainTopicList.length > 0
                  ? response.data.data.mainTopicList[0].assignmentId
                  : 0;

              that.extractedCourseIds = that.course_Items.map(
                (item) => item.idOfCourse
              ); // get already selected course id

              if (response.data.data.mainTopicList.length == 0) {
                that.AddMainTopic();
                that.AddSubTopic();
              }
              that.crudloading = false;
            } else {
              that.AddMainTopic();
              that.AddSubTopic();
              that.crudloading = false;
            }
          }
        })
        .catch(function (err) {
          throw err;
        });
    },
  },
};
</script>

<style scoped>
.TRtopicsavebtn {
  font-size: 18px;
}
.TRtopicbtn {
  font-size: 16px;
  border: 1px solid #a6cc39;
  color: #a6cc39;
  background-color: #ffffff !important;
}
.smallBtnSub.v-btn:not(.v-btn--round).v-size--default {
  height: 36px;
  min-width: unset;
  padding: 0 16px;
  color: #ffffff;
  background-color: #a6cc39;
  top: 37px !important;
  right: 33px;
}
.smallBtnCourse.v-btn:not(.v-btn--round).v-size--default {
  height: 36px;
  min-width: unset;
  padding: 0 16px;
  color: #ffffff;
  background-color: #a6cc39;
  top: 35px !important;
}
.smallBtnDelete.v-btn:not(.v-btn--round).v-size--default {
  height: 36px;
  min-width: unset;
  padding: 0 16px;
  color: #ff6060;
  background-color: #ffffff;
  top: 35px !important;
}
.smallBtnMain.v-btn:not(.v-btn--round).v-size--default {
  height: 36px;
  min-width: unset;
  padding: 0 16px;
  color: #ffffff;
  background-color: #a6cc39;
  top: 36px !important;
  right: 50px;
}
.custom_main_closeBtn {
  background-color: #ff6060 !important;
  border-radius: 8px !important;
  color: #ffffff;
  box-shadow: unset;
  top: 4px !important;
  right: 4px !important;
}
.custom_main_closeBtn.v-btn--fab.v-size--x-small {
  height: 25px !important;
  width: 25px !important;
}
.searchinlist.v-text-field {
  margin-left: 5px;
  padding-top: 0px;
  margin-top: 0px;
}
.custom-border {
  border-bottom: 1px solid #c0c0c0 !important;
  margin-right: 1rem;
  margin-left: 1rem;
}
.divider {
  margin-top: 5px; /* Adjust the spacing above the divider */
  width: calc(
    100% - 50px
  ); /* Adjust the width to account for the radio button and spacing */
  margin-left: 0px; /* Adjust the left margin to align with the radio button */
  border-bottom: 1px solid #000; /* Adjust the color and style of the divider */
}
::v-deep.v-text-field.v-text-field--solo:not(.v-text-field--solo-flat)
  > .v-input__control
  > .v-input__slot {
  box-shadow: unset !important;
  border-radius: 8px;
}
::v-deep .primary--text {
  color: #a6cc39 !important;
  caret-color: #a6cc39 !important;
}
::v-deep .v-text-field.v-text-field--enclosed .v-text-field__details {
  padding-left: 4px;
  margin-bottom: 0;
}
.course-container {
  width: auto;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
::v-deep .custom-label-color .v-label {
  color: #000000de;
}
</style>
